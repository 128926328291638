/* Hero Section */
.proservices-hero {
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: url("../../assets/Hero/professional.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}

/* Section Title (for major headings like "Netskope Implementation Services") */
.section-title-container {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 0.2rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  color: #0167db;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.black-text {
  color: #000000;
}

/* Section Description */
.section-description {
  font-size: 1.1rem;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
  text-align: center;
}

/* Deployment Packages Section */
.proservices-body {
  background-color: #f7f8fc;
  padding: 3rem 1.5rem;
}

.text-primary {
  color: #0167db;
}

/* Category Title */
.category-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #0167db;
  text-align: left;
  border-bottom: 3px solid #0167db;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Deployment Package Styling */
.deployment-package {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.deployment-package:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
}

.package-content {
  max-width: 75%;
}

.package-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: #343a40;
}

.package-description {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;
  margin: 0;
}

/* Contact Us Button */
.package-action {
  display: flex;
  align-items: center;
}

.learn-more-btn {
  background-color: #0167db;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.learn-more-btn:hover {
  background-color: #014a9b;
  transform: translateY(-2px);
}

.section-title {
  color: #000000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.8rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .category-title {
    font-size: 1.6rem;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .deployment-package {
    flex-direction: column;
    align-items: flex-start;
  }

  .package-content {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .learn-more-btn {
    width: 100%;
    text-align: center;
  }

  .proservices-hero {
    min-height: 60vh;
    background-image: url("../../assets/Mobile/professional.webp");
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .category-title {
    font-size: 1.6rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .deployment-package {
    flex-direction: row;
  }

  .proservices-hero {
    height: 50vh;
    background-image: url("../../assets/Hero/professional.webp");
  }

  .hero-content h1 {
    font-size: 2.2rem;
  }
}

@media (min-width: 1025px) {
  .proservices-hero {
    height: 60vh;
  }
}
