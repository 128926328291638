/* General Container Styles */
.product-container {
    background-color: #eff6ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

/* Header Styles */
.header {
    text-align: center;
    margin-bottom: 3rem;
}

.header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #000;
}

.blue-text {
    color: #0167db;
    font-weight: bold;
}

/* Fusion Section Styles */
.fusion-section {
    text-align: center;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding: 0;
}

.fusion-section h2 {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.section-description {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 1rem;
    color: #555;
    text-align: center;
    line-height: 1.6;
}



.image-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.image-container1 {
    margin-top: -1.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.responsive-image1,
.responsive-image {
    max-width: 90%;
    height: auto;
    display: block;
}

.responsive-image {
    max-width: 80%;
    margin-top: 50px;
    margin-bottom: -30px;
}

@media (max-width: 768px) {
    .image-container img.responsive-image[data-mobile] {
        content: url("../../assets/processmobile.webp");
        content: url("../../assets/architecturemobile.webp");
    }
}

.service-plan-header {
    margin-top: -20px;
    text-align: center;
    margin-bottom: 2rem;
}

/* Responsive Behavior */
@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        gap: 1.5rem;
    }

    .plan {
        width: 60%;
        margin: 0 auto;
    }

    .custom-list {
        min-height: auto;
    }
}
  
.product-body {
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Mobile/product.webp");
}

@media (min-width: 769px) and (max-width: 1024px) {
    .product-body {
        background-image: url("../../assets/Hero/product.webp");
    }
}

@media (min-width: 1025px) {
    .product-body {
        background-image: url("../../assets/Hero/product.webp");
    }
}
