/* Container Section */
.why-choose-section {
    background-color: #ffffff;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}

/* Title Styling */
.title-container {
    margin-bottom: 40px;
}

.why-choose-title {
    margin-top: 50px;
    font-size: 2.3rem;
    font-weight: bold;
    color: #0167db;
}

.fusionnode-text {
    color: #0167db;
}

/* Grid Layout for Cards */
.why-choose-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 Cards in first row */
    gap: 30px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
}

/* Center last row if it has 2 items */
.why-choose-grid:has(:nth-child(5)) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.why-choose-grid.five-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 30px;
}

/* Card Styling */
.why-choose-card {
    background: #f8f9fa;
    border-radius: 16px;
    padding: 50px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    width: 100%;
    max-width: 280px; /* Adjust width for better balance */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Ensure content is evenly spaced */
    /* justify-content: space-between;  */
    min-height: 250px; /* Adjust height for uniformity */
}

/* .why-choose-card >*{
    border: 1px solid;
} */

.why-choose-card > div:first-child{
    /* background-color: red; */
    height: 8em;
}


/* Card Hover Effect */
.why-choose-card:hover {
    transform: translateY(-5px);
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
}

/* Center text properly */
.card-title-wc {
    font-size: 18px;
    font-weight: bold;
    color: #0167db;
    margin-top: 15px;
    margin-bottom: 10px;
}

/* Ensure text wraps properly and is centered */
.card-text-wc {
    font-size: 14px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 100%; 
    max-width: 100%;
    text-align: center;
    margin-top: 15px; 
    flex-grow: 1; 
    justify-content: center;
}


/* Icon Styling */
.check-icon {
    font-size: 40px;
    color: #0167db;
    margin-bottom: 15px;
}

/* Custom Icon Styling */
.custom-icon {
    width: 60px; /* Adjust size as needed */
    height: 60px;
    margin-bottom: 0px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .why-choose-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    }
}

@media (max-width: 576px) {
    .why-choose-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 column on mobile */
    }
}
