.archi-card{
  background-color: #fff;
  box-shadow: 10px 10px 15px rgba(1, 103, 219, 0.3);
  width: 20rem;
  height: 20rem;
  border-radius: 12px;
  overflow: hidden;
}

.archi-p, .archi-title {
  text-overflow: ellipsis;
  white-space: no-wrap;
  overflow: hidden;
}


.mesh-container {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
}

.mesh-archi {
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
}

.mesh-title {
  margin: 5vh 0 5vh;
}

.mesh-row {
  max-width: 1200PX;
  margin: 0 0 10vh;
}
.mesh-p {
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
}


@media (max-width: 768px) {
  .mesh-title {
    font-size: 20px;
  }
  .mesh-p {
    margin: 0 1rem 0 1rem;
  }
  .archi-p {
    font-size: 12px;
  }
  .archi-title{
    font-size: 14px;
  }
  .mesh-icon {
    width: 5rem;
  }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .archi-title {
      font-size: 16px;
    }
    .archi-p {
      font-size: 12px;
    }
    .mesh-p {
      margin: 0 5rem 0 5rem;
    }
    .mesh-icon {
      width: 1rem;
    }
  }

  @media (min-width: 1025px) {
    .archi-title {
      font-size: 18px;
    }
    .archi-p {
      font-size: 13px;
    }
    .mesh-p {
      margin: 0 7rem 0 7rem;
    }
    .mesh-icon {
      width: 5rem;
    }
  }