/* WHO WE HELP SECTION */
.who-we-help-section {
  background-color: #ffffff;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

/* TITLE */
.who-we-help-section h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #0167db;
  margin-bottom: 50px;
}

/* ROW STYLING */
.who-we-help-row {
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center;
  white-space: nowrap; /* Ensures content stays in a single row */
  padding-bottom: 10px; /* Adds some space below for better visibility */
}

/* CARD STYLING */
.who-we-help-card {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 10px;
  max-width: 280px;
  min-height: 340px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #0167db; /* Blue border */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%; /* Ensures equal card height */
  flex: 0 0 auto; /* Ensures all cards stay in one row */
  width: 300px; /* Fixed width to keep alignment */
}

/* Card Hover Effect */
.who-we-help-card:hover {
  transform: translateY(-5px);
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
}

/* Ensure Cards Have Equal Height */
.who-we-help-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures title, icon, and text stay spaced */
  height: 100%;
}

/* Make Description Uniform */
.card-text {
  flex-grow: 1; /* Expands to take available space */
  display: flex;
  flex-direction: column;
  min-height: 120px; /* Adjust height based on the longest description */
  font-size: 14px;
  text-align: justify;
  line-height: 1.6;
  word-wrap: break-word;
  max-width: 100%;
  margin-top: 10px;
  padding-left: 20px; /* Ensures proper indentation */
  list-style-type: disc; /* Ensure bullets are shown */
}

/* INDIVIDUAL LIST ITEM */
.card-text li {
  margin-bottom: 10px;
  display: list-item;
  list-style-type: disc; /* Explicitly enforce bullets */
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  height: 8em;
}

/* ICON IMAGE */
.who-we-help-icon {
  width: 70px; /* Slightly bigger */
  height: 70px;
  margin-bottom: 15px;
}

/* CARD TITLE */
.card-title {
  font-size: 18px;
  font-weight: 700;
  color: #0066cc;
  margin-bottom: 10px;
  text-align: center;
  max-width: 90%;
  white-space: normal;
  word-break: break-word;
}

/* RESPONSIVE DESIGN */
@media (max-width: 1024px) {
  .who-we-help-row {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .who-we-help-card {
    max-width: 90%;
  }

  .who-we-help-row {
    flex-direction: column;
    align-items: center;
  }

  .card-title {
    font-size: 16px;
  }

  .card-text {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .who-we-help-card {
    padding: 20px;
  }

  .who-we-help-icon {
    width: 60px;
    height: 60px;
  }

  .card-title {
    font-size: 14px;
  }

  .card-text {
    font-size: 12px;
  }
}
