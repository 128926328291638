/* 🔹 General Dropdown Styles */
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #0167db;
  border: none;
  transition: opacity 0.2s ease-in-out;
  min-width: 200px; /* Ensures a good width */
}

.dropdown-item {
  color: white;
  padding: 10px 15px;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* 🔹 Remove Nested Dropdowns (Since They're No Longer Needed) */
.sub-dropdown-menu,
.sub-sub-dropdown-menu {
  display: none !important;
}

/* 🔹 Optional: Add a Small Shadow for Better Visibility */
.dropdown-menu {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
