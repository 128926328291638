/* NavBar */
.dropdown-toggle::after {
  display: none;
}

.nav-dropdown {
  position: relative; 
}

/* Hide dropdown by default */
.nav-dropdown .dropdown-menu {
  position: absolute; 
  z-index: 1000; 
}


.navbar {
  position: fixed;  /* Make the navbar fixed at the top */
  top: 0;  /* Align it to the top of the screen */
  left: 0;  /* Ensure it's aligned to the left */
  width: 100%;  /* Make the navbar span the full width */
  z-index: 1000;  /* Ensure it stays above other content */
  background-color: #0167db;
}

/* Navbar.css */
.nav-link {
  font-weight: normal; /* Default weight */
  color: #fff;
  transition: color 0.3s ease, font-weight 0.1s ease; /* Smooth transition for color and weight */
  margin-right: 10px;
  scroll-behavior: auto;
}

.nav-link:hover {
  font-weight: bold; /* Only make bold on hover */
}

.navbar .nav-link {
  color: #ffffff !important; /* Replace with your desired color */
}

.navbar .nav-link:focus,
.navbar .nav-link:hover,
.navbar .nav-link:active,
.apply-now {
  color: #ffffff !important; /* Keeps the color consistent */
  text-decoration: none; /* Optional: Removes underline if present */
}

.navbar .nav-link:focus {
  outline: none;
}

@media (max-width: 768px) {
  .navbar .nav {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: flex-start; /* Align links to the left */
  }
  .apply-now-ico{
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .navbar .nav {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: flex-start; /* Align links to the left */
  }
  .apply-now-ico{
    display: none;
  }

}
  @media (min-width: 1025px) {
   .navbar .apply-now{
      /* background-color: #EFF7FF; */
      border-radius: 6px;
      color:#ffffff !important;
      border-color: #0167db;


    }

    /* .navbar .apply-now:hover{
      color:#0167db !important;

    } */

    .apply-now-ico{
      height: 22px;
      padding-right: 5px;
    }


}