.footer {
  background-color: #252E44;
  color: #fff;
}

.email-p {
  cursor: pointer;
}
.privacy-text{
  text-decoration: none;
}
.privacy-text:hover{
  color: #0056b3 !important;
  text-decoration: underline;
}

.left-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 3rem 5em 0 20rem;

}

.link-box a {
  color: #fff;
  text-decoration: none;
}


@media (max-width: 768px) {
  .footer-lg{
    display: none !important;
  }
  .footer-md{
    display: none !important;
  }
  .img-sm-footer{
    width: 10rem;   
  }
  .link-box {
    margin-bottom: 1em;
  }
  .link-box a {
    margin: 0.5em 0 0 0;
  }
  .link-box p{
    font-size: small;
  }
  .bottom-part {
    width: 100%;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bottom-part p{
    text-align: center;
  }
  .click-privacy {
    margin-top: 1.5rem;    
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer-lg{
    display: none !important;
  }
  .footer-sm{
    display: none !important;
  }
  .img-md-logo{
    width: 170px;
    margin: 2rem 1rem 1rem 5rem;
  }
  .footer{
    height: auto;
  }
  .link-box {
    margin-top: 5rem;
    text-align: left;
  }
  .link-box a {
    margin: 0.5rem;
  }
  .link-box p{
    margin-top: 1rem;
    font-size: small;
    margin: 0.5rem;
  }
  .click-privacy {
    margin-top: 12px;
  }
}

@media (min-width: 1025px) {
  .footer-md{
    display: none !important;
  }
  .footer-sm{
    display: none !important;
  }
  .bottom-part {
    margin: 0 5rem 0 5rem;
  }
  .link-box {
    text-align: left;
  }
  .link-box a {
    margin: 0.2rem;
  }
  .link-box p{
    margin-top: 1rem;
    font-size: small;
  }
  .img-logo {
    width: 8rem;
    height: auto;
    margin: 1rem 1rem 1rem 5rem;
  }
}