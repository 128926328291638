/* Services */
.cardSPage-icon {
  margin-bottom: 5vh;
  width: 10rem;
  margin: 0 10vh 0 8vh;
  border-radius: 10px;
}

.text-box {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.text-box button {
  width: 8rem;
  height: 1.5rem;
  background-color: #EFF7FF;
  color: #0167DB;
  border: solid #0167DB 0.5px;
  font-size: 0.8rem;
  
}

.cardServices {
    background-color: #F6F6F6;
    height: 16rem;
    width: 50rem;
    border-radius: 12px;
    overflow-y: scroll;
    overflow: hidden;
    border: none;
}

.services-icon {
  background-color: #0167DB; /* Blue background */
  color: #fff; /* White icon color */
  border-radius: 50%; /* Make the background circular */
  width: 50px; /* Adjust as needed for circle size */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardServices:hover,
.cardServices:hover p {
  background-color: #0167DB;
  color: #fff;
}

.cardServices:hover .services-icon {
  background-color: #fff; /* White background */
  color: #0167DB; /* Blue icon color */
}

.cardServices:hover .learn-more {
  font-weight: bold; /* Bold font for learn-more only */
}


.service-info {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learn-more {
  cursor: pointer;
  position: absolute;
  bottom: 1px; 
  right: 15px;
}

.service-popup {
  background-color: #0167DB;
  color: #fff;
  width: 40rem;
}

.swal-close-button {
  font-size: 1.5rem;
  color: #fff;
  border: none;
}

.cardSPage-row {
  max-width: 1200PX;
  margin: 0 0 10vh;
}

.service-image {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .row{
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .cardServices {
    width: 100%
  }
  .cardSPage-icon{
    display: none;
  }
  .button-sm{
    width: 100% !important;
  }
  .para-more {
    font-size: 12px;
  }
  
.service-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Mobile/services.webp");
}
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cardServices {
    width: 100%
  }
  .cardSPage-icon{
    display: none;
  }
  .para-more {
    font-size: 12px;
  }
  /* .service-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Hero/services.webp");
  } */
}

@media (min-width: 1025px) {
  /* .service-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Hero/services.webp");
  } */
}
