/* Main Container */
.employer-container {
    background-color: #eff6ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

/* Title and Subheading */
.main-title-eor {
    font-size: 2.3rem;
    font-weight: bold;
    color: #252E44;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 15px;
    max-width: 900px; /* Adjust the width as needed */
    margin-left: auto;
    margin-right: auto;
}


.sub-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0167db;
    text-align: center;
    margin-bottom: 30px;
}

.sub-title-foot {
    font-size: 1rem;
    color: #000000;
    text-align: center;
    padding: 20px;
}

.main-title-foot {
    font-size: 2.3rem;
    font-weight: bold;
    color: #0167db;
    text-align: center;
    padding-bottom: 5rem;

}

/* Highlighted Text */
.highlight {
    color: #0167db;
    font-weight: bold;
}

/* Description Background */
.description-box {
    background-color: #252E44;
    padding: 1rem;
    text-align: center;
    color: #ffffff;
    width: 100%;
}

/* Description Text */
.description-box p {
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: white;
}

/* Company Name */
.company-name {
    color: #0167db;
    font-weight: bold;
}

/* Footer Text */
.footer-text {
    margin-top: 20px;
    font-size: 1rem;
    color: black;
    text-align: center;
}

/* How It Works Section */
.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.how-it-works {
    font-size: 2.3rem;
    font-weight: bold;
    color: #0167db;
    margin-top: 50px;
    margin-bottom: 50px;
}

/* Background Image */
.employer-body {
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Mobile/employer.webp");
}

/* Email Link Styling */
.email-link {
    text-decoration: none; /* Removes underline */
    color: #0167db; /* Matches existing style */
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.email-link:hover {
    color: #014b9b; /* Darker blue on hover */
}

/* Responsive Images */
.your-image-class {
    width: 100%;
    max-width: 1000px;
    height: auto;
    display: block;
}

@media (min-width: 768px) {
    .your-image-class {
        width: 80%;
        max-width: 700px;
    }

    .employer-body {
        background-image: url("../../assets/Hero/employer.webp");
    }
}

@media (min-width: 1024px) {
    .your-image-class {
        width: 70%;
        max-width: 1000px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .employer-body {
        background-image: url("../../assets/Hero/employer.webp");
    }
}

@media (min-width: 1025px) {
    .employer-body {
        background-image: url("../../assets/Hero/employer.webp");
    }
}
